import _ from 'lodash'
import { EventBus } from '@/common/eventBus';

import axios from 'axios'
import { urls, serverUrls } from '@/config'
import httpClient from "@/services/http.service";

export default {
  namespaced: true,
  state: {
    loading: true,
    currentPage: 1,
    perPage: 10,
    perPageMore: 10,
    totalCount: 0,
    currentRightRange: 0,
    list: [],
    columns: [],
    appliedFilters: {
    },
    isHideInactive: false,
    showSetup: false,
    tenderInfo: {}
  },
  actions: {
    async load({ commit }, params) {
      /* Emulating network request */
      const { showMore = false, demoPage = false } = params;
      commit('update', { commit, showMore, demoPage })
    },
    loadInfo({ commit }, id) {
      httpClient.get(`${urls.apiTendersUrl}/info/${id}`).then(res => {
        commit('setTenderInfo', res.data)
      }).catch(err => {
        console.log(err)

        const mockData = {
          "id": 0,
          "name": "string",
          "author": {
            "id": 0,
            "avatar": "string",
            "name": "string",
            "email": "Unknown Type: email",
            "phone": "string"
          },
          "extanded_info": {
            "current_status_id": 0,
            "dt_start": "2022-03-25T13:03:23.862Z",
            "dt_end": "2022-03-25T13:03:23.862Z",
            "dt_plan_start": "2022-03-25T13:03:23.862Z",
            "dt_plan_end": "2022-03-25T13:03:23.862Z",
            "dt_create": "2022-03-25T13:03:23.862Z",
            "dt_update": "2022-03-25T13:03:23.862Z",
            "description_short": "string",
            "description_full": "string",
            "category_id": 0,
            "project_id": 0,
            "object_id": 0,
            "tender_scenario_id": 0,
            "period_execution": 0,
            "preliminary_cost": "string"
          },
          "files": [
            {
              "name": "string",
              "dt_create": "2022-03-25T13:03:23.862Z",
              "size": 0,
              "link": "string",
              "type": "deal"
            },
            {
              "name": "string",
              "dt_create": "2022-03-25T13:03:23.862Z",
              "size": 0,
              "link": "string",
              "type": "deal"
            },
            {
              "name": "string",
              "dt_create": "2022-03-25T13:03:23.862Z",
              "size": 0,
              "link": "string",
              "type": "data"
            }
          ]
        }

        commit('setTenderInfo', mockData)
      })
    }
  },
  mutations: {
    setTotalCount(state, count) {
      state.totalCount = count
    },
    setLoading(state, isLoading) {
      state.loading = isLoading
    },
    setCurrentPage(state, page) {
      state.currentPage = page
    },
    setPerPageAmount(state, perPage) {
      state.perPage = perPage
    },
    update(state, { commit, showMore, demoPage }) {
      commit('setLoading', true)

      const filters = JSON.stringify({
        id: _.get(state, 'appliedFilters.id.value', null), 
        name: _.get(state, 'appliedFilters.name.value', null), 
        categories: _.get(state, 'appliedFilters.categories.value', null), 
        project: _.get(state, 'appliedFilters.project.value', null), 
        budget: _.get(state, 'appliedFilters.budget.value', null), 
        description: _.get(state, 'appliedFilters.description.value', null), 
        //status: _.get(state, 'appliedFilters.status.value', null), 
        isHideInactive: state.isHideInactive, 
      });

      if(showMore) state.perPage = state.perPage + 10;

      let tendersListMapped = [];
      const currentPage = demoPage ? 1 : state.currentPage;
      const perPage = demoPage ? 10 : state.perPage;
      const apiTendersUrl = demoPage ? urls.apiTendersDemoUrl : urls.apiTendersUrl;

      httpClient.get(`${serverUrls.backendUrl}/${apiTendersUrl}?perPage=${perPage}&page=${currentPage}&filters=${filters}`)
        .then(response => {
          commit('setLoading', false)

          state.currentRightRange = 10

          if (response && response.data && response.data.items && response.data.items.length) {
            response.data.items.map((item, i) => {
              tendersListMapped[i] = {
                id: item.id,
                name: item.name,
                budget: item.preliminary_cost,
                categories: item.category_id,
                date_from: item.dt_plan_start,
                date_to: item.dt_plan_end,
                description: item.description_full,
                project: item.project_id,
                //project: 1,
                requestsCount: 122,
                status: item.is_active,
                current_status_id: item.current_status_id,
              }
            });

            state.list = _.cloneDeep(tendersListMapped)

            state.totalCount = response.data.count;
          } else {
            state.list = [];
            state.totalCount = 0;
          }
        });
    },
    setFilterValue(state, { column, value }) {
      if (!(column in state.appliedFilters)) state.appliedFilters[column] = {};
      if (!state.appliedFilters[column]) state.appliedFilters[column] = {}

      state.appliedFilters[column].value = value;
      state.appliedFilters = _.cloneDeep(state.appliedFilters)

      EventBus.$emit("tenderFiltersUpdated");
    },
    resetFilters(state) {
      state.appliedFilters = {};
    },
    setHideInactive(state, hide) {
      state.isHideInactive = hide
    },
    toggleShowSetup(state) {
      state.showSetup = !state.showSetup;
    },
    setColumnsInitial(state, columns) {
      state.columns = columns
    },
    setColumns(state, columns) {
      state.columns = []
      setTimeout(() => {
        state.columns = columns
      },
        100
      );
    },
    setTenderInfo(state, info) {
      state.tenderInfo = _.cloneDeep(info)
    }
  },
  getters: {
    loading: state => state.loading,
    list: state => state.list,
    columns: state => state.columns,
    appliedFilters: state => state.appliedFilters,
    isHideInactive: state => state.isHideInactive,
    perPage: state => state.perPage,
    currentPage: state => state.currentPage,
    totalCount: state => state.totalCount,
    showShowMore: state => {
      const rightRange = state.currentRightRange;
      return rightRange < state.totalCount;
    },
    tenderInfo: state => state.tenderInfo
  },
}
