//import axios from 'axios'
//import { urls } from '@/config'
import _ from 'lodash'

const items = [
  {
    id: 1,
    img: require('@/assets/img/partners/partner1.jpg')
  },
  {
    id: 2,
    img: require('@/assets/img/partners/partner2.jpg')
  },
  {
    id: 3,
    img: require('@/assets/img/partners/agroup.png')
  },
  {
    id: 4,
    img: require('@/assets/img/partners/dipos.svg')
  },
  {
    id: 5,
    img: require('@/assets/img/partners/harpoon.png')
  },
  {
    id: 6,
    img: require('@/assets/img/partners/tehnostyle.png')
  },
  /* {
    id: 3,
    img: require('@/assets/img/partners/partner3.jpg')
  },
  {
    id: 4,
    img: require('@/assets/img/partners/partner4.jpg')
  },
  {
    id: 5,
    img: require('@/assets/img/partners/partner5.jpg')
  },
  {
    id: 6,
    img: require('@/assets/img/partners/partner1.jpg')
  },
  {
    id: 7,
    img: require('@/assets/img/partner2.jpg')
  },
  {
    id: 8,
    img: require('@/assets/img/partner3.jpg')
  },
  {
    id: 9,
    img: require('@/assets/img/partner4.jpg')
  },
  {
    id: 10,
    img: require('@/assets/img/partner5.jpg')
  },
  {
    id: 11,
    img: require('@/assets/img/partner1.jpg')
  },
  {
    id: 12,
    img: require('@/assets/img/partner2.jpg')
  },
  {
    id: 13,
    img: require('@/assets/img/partner3.jpg')
  },
  {
    id: 14,
    img: require('@/assets/img/partner4.jpg')
  },
  {
    id: 15,
    img: require('@/assets/img/partner5.jpg')
  } */
]

export default {
  namespaced: true,
  state: {
    list: [],
    partnersPerPage: 5,
    currentPartnersCount: 0
  },
  actions: {
    async load({ commit, getters }) {
      const currentPartnersCount = getters.currentPartnersCount
      if (currentPartnersCount < items.length) {
        commit('update', items)
        commit('increaseCurrentPartnersCount')
      }
    },
  },
  mutations: {
    update(state, partnersData) {
      state.list = _.cloneDeep(partnersData)
    },
    increaseCurrentPartnersCount(state) {
      state.currentPartnersCount+=state.partnersPerPage
    }
  },
  getters: {
    list: state => state.list,
    currentPartnersCount: state => state.currentPartnersCount,
    partnersPerPage: state => state.partnersPerPage,
    showLoadButton: state => items.length > state.currentPartnersCount
  },
}
