import axios from 'axios'
//import { urls } from '@/config'
// import _ from 'lodash'

const inintialState = {
  transportTypes: [
    {
      name: 'Курьераской службой',
      value: 'delivery'
    },
    {
      name: 'Автомобильным транспортом',
      value: 'car'
    },
    {
      name: 'ЖД транспортом',
      value: 'train'
    },
    {
      name: 'Контейнерная перевозка',
      value: 'container'
    },
    {
      name: 'Мультимодальная перевозка',
      value: 'multimodal'
    },
    {
      name: 'Водным транспортом',
      value: 'water'
    },
  ],
  supplyConditions: [
    {
      name: 'ИНКОТЕРМС',
      value: '1'
    }
  ],
  deliveryConditions: [
    {
      name: 'Самовывоз',
      value: '1'
    },
    {
      name: 'Силами поставщика с последующей компенсацией покупателем затрат на перевозку',
      value: '2'
    },
    {
      name: 'Силами поставщика за счет поставщика',
      value: '3'
    },
  ],
  tenderPosTableColumns: [
    {
      field: 'nomenclature',
      label: 'НОМЕНКЛАТУРА',
      width: 400,
      type: 'description'
    },
    {
      field: 'venderCode',
      label: 'АРТИКУЛ',
    },
    {
      field: 'measureUnit',
      label: 'ЕД.ИЗМ',
    },
    {
      field: 'quantity',
      label: 'КОЛ-ВО',
    },
    {
      field: 'materialUnit',
      label: 'ЕДИНИЧ. расц. мат-лов, руб',
      width: 85,
      type: 'editable'
    },
    {
      field: 'workUnit',
      label: 'ЕДИНИЧ. расц. работ, руб',
      width: 85,
      type: 'editable'
    },
    {
      field: 'note',
      label: 'ПРИМЕЧАНИЕ',
      width: 85,
      type: 'editable'
    },
    {
      field: 'usd',
      label: '$',
      type: 'checkable'
    },
    {
      field: 'materialPrice',
      label: 'стоим. мат-лов, руб',
      type: 'computable',
    },
    {
      field: 'workPrice',
      label: 'стоим. работ, руб',
      type: 'computable',
    },
    {
      field: 'ndsPrice',
      label: 'стоим. с ндс, руб',
      type: 'computable'
    },
  ],
  tenderPosTableData: [
    {
      nomenclature: {
        title: 'Профнастил оцинкованый Н60-845',
        description: 'Профнастил оцинкованый Н60-845'
      },
      venderCode: '',
      measureUnit: 'Компл',
      quantity: '',
      materialUnit: '',
      workUnit: '',
      note: '',
      usd: false,
      materialPrice(usdPrice = 1) {return parseFloat(this.quantity*this.materialUnit*(this.usd ? usdPrice:1)).toFixed(3)},
      workPrice(usdPrice = 1) {return parseFloat(this.quantity*this.workUnit*(this.usd ? usdPrice:1)).toFixed(3)},
      ndsPrice(usdPrice = 1) {return parseFloat(this.materialUnit*this.workUnit*(this.usd ? usdPrice:1)).toFixed(3)}
    },
    {
      nomenclature: {
        title: 'Профнастил оцинкованый Н60-845',
        description: 'Профнастил оцинкованый Н60-845',
        child: true
      },
      venderCode: '',
      measureUnit: 'Компл',
      quantity: '5',
      materialUnit: '',
      workUnit: '',
      note: '',
      usd: false,
      materialPrice(usdPrice = 1) {return parseFloat(this.quantity*this.materialUnit*(this.usd ? usdPrice:1)).toFixed(3)},
      workPrice(usdPrice = 1) {return parseFloat(this.quantity*this.workUnit*(this.usd ? usdPrice:1)).toFixed(3)},
      ndsPrice(usdPrice = 1) {return parseFloat(this.materialUnit*this.workUnit*(this.usd ? usdPrice:1)).toFixed(3)}
    }
  ],
  USDPrice: null,
  tenderPreview: [
    {
      name: 'Информация о тендере',
      params: [
        {
          name: 'текущий статус:',
          value: 'ОТКРЫТ',
          type: 'status'
        },
        {
          name: 'Вид тендера:',
          value: 'Тендер',
        },
        {
          name: 'ДАТА НАЧАЛА ПРОВЕДЕНИЯ:',
          value: '29 декабря 2021, 10:02',
        },
        {
          name: 'ДАТА окончания ПРОВЕДЕНИЯ:',
          value: '29 декабря 2021, 10:02',
        },
        {
          name: 'НОМЕНКЛАТУРА:',
          value: 'Профнастил Н114-750',
        },
        {
          name: 'КРАТКОЕ ОПИСАНИЕ:',
          value: 'Профнастил Н114-750',
        },
        {
          name: 'ОПИСАНИЕ:',
          value: 'Профнастил Н114-750',
        },
        {
          name: 'Категория:',
          value: 'ТМЦ',
        },
        {
          name: 'Проект:',
          value: 'Фермы',
        },
        {
          name: 'Обьект:',
          value: 'Фермы',
        },
        {
          name: 'дата начала исполнения:',
          value: '29 декабря 2021, 10:02',
        },
        {
          name: 'дата окончания исполнения:',
          value: '29 декабря 2021, 10:02',
        },
        {
          name: 'СРОК ИСПОЛНЕНИЯ, дней:',
          value: '20',
        },
        {
          name: 'Разрешить ввод нулевых строк:',
          value: 'Да',
        }
      ]
    },
    {
      name: 'Заявка',
      params: [
        {
          name: 'СТоимость с учетом ндс, руб',
          value: '1 000 000 000',
        },
        {
          name: 'условия ПОСТАВКИ:',
          value: '-',
        },
        {
          name: 'Затраты на доставку:',
          value: '50 000',
        },
        {
          name: 'срок поставки:',
          value: '29 декабря 2021, 10:02',
        },
        {
          name: 'вид танспорта:',
          value: 'Авто',
        },
        {
          name: 'порядок доставки:',
          value: 'Самовывоз',
        },
        {
          name: 'условия расчетов:',
          value: 'Аванс',
        },
        {
          name: 'Сроки предоплаты:',
          value: '5 дней',
        },
      ]
    }
  ]
}

export default {
  namespaced: true,
  state: inintialState,
  actions: {
    getUSDPrice({commit}) {
      axios.get('https://www.cbr-xml-daily.ru/latest.js').then(res => {
        console.info(res)
        commit('setUSDPrice', res.data.rates.USD)
      })

    }
  },
  mutations: {
    setUSDPrice(state, value) {
      state.USDPrice = value
    }
  },
  getters: {
    transportTypes: state => state.transportTypes,
    supplyConditions: state => state.supplyConditions,
    deliveryConditions: state => state.deliveryConditions,
    tenderPosTableColumns: state => state.tenderPosTableColumns,
    tenderPosTableData: state => state.tenderPosTableData,
    USDPrice: state => state.USDPrice,
    tenderPreview: state => state.tenderPreview
  },
}