<template>
  <router-view />
</template>

<script>

export default {
  head: {
    title: 'Тендерная площадка',
  }
}

</script>
