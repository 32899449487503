import httpClient from "./http.service";
import router from "../router";

const authService = {
  currentUser: null,
  isLoggedIn() {
    return !!localStorage.getItem('ACCESS_TOKEN')
  },
  getToken() {
    return localStorage.getItem('ACCESS_TOKEN')
  },
  getUsername() {
    return localStorage.getItem('USERNAME')
  },
  async login(formData) {
    try {
      const { status, data } = await httpClient.post('/restapi/auth', formData);
      if (status === 200) {
        localStorage.setItem('ACCESS_TOKEN', data.access_token)
        localStorage.setItem('USERNAME', data.username)
      }
      return {
        success: true
      }
    } catch (e) {
      console.info(e.response);
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  async register(formData) {
    try {
      const { status, data } = await httpClient.post('/restapi/sign-up', formData);
      if (status === 200) {
        localStorage.setItem('ACCESS_TOKEN', data.access_token)
      }
      return {
        success: true
      }
    } catch (e) {
      console.info(e.response);
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  logout() {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('USERNAME');
    router.go({ name: "Home" });
  },
  async recover(email) {
    try {
      const { status, data } = await httpClient.post('/restapi/recover', { email: email });

      return {
        success: true,
        message: data.message
      }
    } catch (e) {
      console.info(e.response);
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  async setPassword(id, code, password) {
    try {
      const data = await httpClient.post('/restapi/set-password', { id: id, code: code, password: password });
      const { success, message, errors } = data.data;

      return {
        success: success,
        message: message,
        errors: errors,
      }
    } catch (e) {
      console.info('e.response', e.response);
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  async getUser() {
    try {
      if (!this.currentUser) {
        const { status, data } = await httpClient.get('/restapi/user/data');
        if (status === 200) {
          this.currentUser = data;
        }
      }

    } catch (e) {
      return null;
    }

    return this.currentUser;
  }
};

export default authService;
