import Vue from 'vue';
import { formatSpacesThousands, deFormatSpacesThousands } from './math'

Vue.mixin({
    methods: {
        roundToZero(num) {
            return +(Math.round(num + 'e+0') + 'e-0');
        },
        roundToOne(num) {
            return +(Math.round(num + 'e+1') + 'e-1');
        },
        roundToTwo(num) {
            return +(Math.round(num + 'e+2') + 'e-2');
        },
        roundToThree(num) {
            return +(Math.round(num + 'e+3') + 'e-3');
        },
        roundToFour(num) {
            return +(Math.round(num + 'e+4') + 'e-4');
        },
        roundToNine(num) {
            return +(Math.round(num + 'e+9') + 'e-9');
        },
        replaceCommaWithDot(num) {
            return (num + '').replace(',', '.');
        },
        formatSpacesThousands(val) {
            return formatSpacesThousands(val)
        },
        deFormatSpacesThousands(val) {
            return deFormatSpacesThousands(val)
        },
    },
});
