import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const loadComponent = path => () => import(`../views/${path}.vue`)
import authService from "../services/auth.service";

const checkRoutesToLoggedIn = [
  '/tenders',
]

const routes = [
  {
    name: '',
    path: '/',
    component: loadComponent('MainTemplate'),
    children: [
      {
        name: 'Home',
        path: '',
        component: loadComponent('Home'),
      },
      {
        name: 'tenders',
        path: 'tenders',
        component: loadComponent('Tenders'),
        checkLogin: true,
      },
      {
        name: 'login',
        path: 'login',
        component: loadComponent('Login'),
      },
      {
        name: 'sign-up',
        path: 'sign-up',
        component: loadComponent('SignUp'),
      },
      {
        name: 'forgot-password',
        path: 'forgot-password',
        component: loadComponent('ForgotPassword'),
      },
      {
        name: 'set-password-after-email',
        path: 'set-password-after-email',
        component: loadComponent('SetPasswordAfterEmail'),
      },
      {
        name: 'rules',
        path: 'rules',
        component: loadComponent('Rules'),
      },
      {
        name: 'help',
        path: 'help',
        component: loadComponent('Help'),
      },
      {
        name: 'contacts',
        path: 'contacts',
        component: loadComponent('Contacts'),
      },
      {
        name: 'request',
        path: 'tenders/request/:id',
        component: loadComponent('TenderRequest'),
      },
      {
        name: 'info',
        path: 'tenders/info/:id',
        component: loadComponent('TenderDetails'),
      },
      {
        path: ':pathMatch(.*)*',
        name: '404',
        component: loadComponent('NotFoundPage'),
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach(async (to, from, next) => {
  if (checkRoutesToLoggedIn.includes(to.fullPath) && !authService.isLoggedIn()) {
    return next('/login');
  }

  next();
})

export default router;