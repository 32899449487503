export const serverUrls = {
  backendUrl: process.env.VUE_APP_BACKEND_URL,
}

export const urls = {
  apiTendersUrl: 'restapi/tenders',
  apiTendersDemoUrl: 'restapi/tenders-demo',
  apiStatisticsUrl: 'restapi/statistics',
  ipiFileGet: '/file/get'
}

export const fileUrls = {
  help:  `${serverUrls.backendUrl}/files/Инструкция_Исполнителя_по_работе_в_АСТ_Тендеры_ЕВРАЗ_Стил_Билдинг.docx`
}
